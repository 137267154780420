import React from "react";
import "./global-footer.scss";

export default () => (
  <div>
  <div className="global-footer flex justify-center text-grey-light text-lg bg-blue">
    <div className="global-footer-item first">
      Krystal & Frank
    </div>
    <div className="global-footer-item">
      10.26.19
    </div>
    <div className="global-footer-item">
      NOLA
    </div>
    <div className="global-footer-item desktop-only">
      Laissez Les Bon Temps Rouler!
    </div>
  </div>
  <div className="white-stripe"/>
  </div>
)
