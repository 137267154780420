import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import GlobalNav from './global-nav';
import GlobalFooter from "./global-footer";
import favicon from '../images/logo.png'

import './fonts.css';
import './layout.scss';
import './index.css';

class Layout extends React.PureComponent {
  render() {
    let {hero, children} = this.props;
    return (
      <>
        <Helmet
          title={"Krystal + Frank"}
          meta={[
            { name: 'description', content: 'Krystal and Frank\'s Wedding - New Orleans 2019' },
            { name: 'keywords', content: 'krystal, frank, wedding' },
          ]}
          link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="flex flex-col justify-center min-h-full">
          {hero}
          <GlobalNav />
          <div className="flex-grow">
            {children}
          </div>
          <GlobalFooter />
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
