import React from 'react'
import Link from 'gatsby-link'

import "./global-nav.scss";

class GlobalNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    document.body.classList.remove('no-scroll')
  }

  toggleOpen = () => {
    const open = !this.state.open
    this.setState({
      open
    }, () => {
      if (open) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    })
  }

  render() {
    return (
      <div className="global-nav-container">
        <div className="mobile-control mobile-only" onClick={this.toggleOpen}>
          <div className={"nav-icon" + (this.state.open ? " open" : "")}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={"global-nav" + (this.state.open ? " mobile-open" : "")}>
          <Link className="global-nav-link" activeClassName="active" to="/">
            Welcome
          </Link>
          <Link className="global-nav-link" activeClassName="active" to="/wedding-details/">
            Wedding Details
          </Link>
          <Link className="global-nav-link" activeClassName="active" to="/the-couple/">
            The Couple
          </Link>
          <Link className="global-nav-link" activeClassName="active" to="/nola/">
            NOLA
          </Link>
          <Link className="global-nav-link" activeClassName="active" to="/rsvp/">
            RSVP
          </Link>
          <Link className="global-nav-link" activeClassName="active" to="/registry/">
            Registry
          </Link>
        </div>
      </div>
    )
  }
}

export default GlobalNav
